<template>
    <div class="grid">
        <div class="col-12">

            <div class="col-12 xl:col-12">
                <div class="card">
                    <h5>Planilhas Importadas por Fornecedores</h5>
                   
                    <DataTable 
                    v-model:filters="filters" 
                    :loading="loading" 
                    :value="planilhas" :rows="5" 
                    :paginator="true"
                    ref="dt"
                    csvSeparator=";"
                    responsiveLayout="scroll"
                        :globalFilterFields="['razao_social', 'data_upload', 'status', 'mes_referencia']">
                        <template #header>
                            <div class="flex justify-content-end">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                </span>
                                <Button label="Exportar Excel" icon="pi pi-upload" class="p-button-success ml-5" @click="exportCSV($event)"/>
                            </div>
                        </template>
                        <Column field="data_upload" header="Data Upload" :sortable="true"></Column>

                        <Column field="razao_social" header="Fornecedor" :sortable="true">

                        </Column>
                        <Column field="mes_referencia" header="Mês Referência" :sortable="true">
                        </Column>
                        <Column field="id" header="Número do Lote" :sortable="true">
                        </Column>
                        <Column field="status" header="Status do Processo" :sortable="true">
                        </Column>
                        <Column field="responsavel" header="Responsável" :sortable="true">
                        </Column>


                        <Column field="qtd_endid_enviado" header="End ID Enviados" :sortable="true">
                        </Column>

                        <Column field="qtd_endid_aprovado" header="End ID Aprovados" :sortable="true">
                        </Column>

                        <Column field="valor_endid_aprovado" header="Valor End ID Aprovados" :sortable="true">
                        </Column>

                        <Column field="qtd_endid_reprovado" header="End ID Reprovados" :sortable="true">
                        </Column>

                        <Column field="valor_endid_reprovado" header="Valor End ID Reprovados" :sortable="true">
                        </Column>

                         <Column style="width: 15%">
                            <template #header> Valores </template>

                            <template #body="slotProps">
                                <Button icon="pi pi-search" @click="mostrarPlanilha(slotProps.data, 1)" type="button"
                                    class="p-button-text"></Button>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>


        </div>
    </div>


    <Dialog v-model:visible="mostrarPlanilhaModal" :style="{ width: '80%' }" header="Planilha Fornecedor" :modal="true">
        <div>
            <DataTable ref="rf" exportFilename="relatorio[CONSOLIDADO]" csvSeparator=";" :value="empresas" :stickyHeader="true"
                  
                v-model:selection="selectedProducts" 
                dataKey="id" 
                :paginator="true"
                :rows="10" :filters="filters"
                
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 25]"
                currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                responsiveLayout="scroll">
                <template #header>
                    <div class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                ">
                        <h5 class="m-0">Dados Consolidados - Compartilhamento</h5>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                        </span>
                        <Button label="Exportar Excel" icon="pi pi-upload" class="p-button-success ml-5" @click="exportCSV2($event)"/>
                    </div>
                </template>

                <Column v-for="col of cabecalho" :field="col.field" :header="col.header" :key="col.field"
                    style="white-space:nowrap">

                </Column>
            </DataTable>

        </div>
    </Dialog>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            mostrarPlanilhaModal: false,
            planilhas: [],
            cabecalho: [
                { header: "Site ID TIM", field: 'site_id_tim' },
                { header: "END ID TIM", field: 'endereco_id_tim' },
                { header: "Regional TIM", field: 'regional_tim' },
                { header: "UF", field: 'uf' },
                { header: "Detentora ", field: 'detentora' },
                { header: "Site ID Detentora", field: 'site_id_detentora' },
                { header: "Data RFI", field: 'data_rfi' },
                { header: "Tipo de Energia", field: 'tipo_de_energia' },
                { header: "Consumo (KWh)", field: 'consumo_kWh' },
                { header: "É viável Instalação de Medidor TIM?", field: 'e_viavel_instalacao_de_medidor_tim' },
                { header: "Justificativa", field: 'justificativa' },
                { header: "Mês de Referência do Formulário", field: 'mes_de_referencia_do_formulario' },
                { header: "Data de envio do formulário", field: 'data_de_envio_do_formulario' },
                { header: "Mês de Referência da Fatura", field: 'mes_de_Referencia_da_fatura' },
                { header: "Valor Total Fatura (R$) ", field: 'valor_total_fatura' },
                { header: "Concatenado (END_ID + Dententora)", field: 'concatenado' },

                //Base Xerox
                { header: "Status Base Cadastral", field: 'status_base_cadastral' },
                { header: "Status SGE", field: 'status_sge' },
                { header: "Data Entrante SGE", field: 'data_entrante_sge' },
                { header: "Status Titularidade", field: 'status_titularidade' },
                { header: "Status Site OPM", field: 'status_site_opm' },
                { header: "ETAPA ATUAL NETFLOW", field: 'etapa_atual_netflow' },

                //regras
                { header: "Classificação do Reembolso", field: 'classificacao_do_reembolso' },
                { header: "Motivo da Contestação", field: 'motivo_da_contestacao' },
                { header: "Valor Contestado (R$)", field: 'valor_contestado' },
                { header: "Valor Validado (R$)", field: 'valor_validado' },
                { header: "Status", field: 'status' },
                { header: "Data da validação", field: 'data_entrante_sge_ajustada' },
                { header: "Link", field: 'link' },
            ],
            empresas: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            empresa: {},
            selectedProducts: null,
            filters: {},
            submitted: false,
            status: 0,
            statuses: [
                { label: "Inativo", value: "0" },
                { label: "Ativo", value: "1" },
            ],
        };
    },
    productService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.listarPlanilhas();

        //this.productService.getProducts().then((data) => (this.products = data));
    },
    methods: {
        mostrarPlanilha(planilha) {
            this.listarRelatorio(planilha.id);
            this.mostrarPlanilhaModal = true;
        },
        listarRelatorio(id_planilha) {
            let dados = {
                id_planilha: id_planilha
            }
            this.axios
                .post("/relatorios/consolidado", dados)
                .then((response) => {
                    console.log(response);
                    this.empresas = response.data.dados;
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        listarPlanilhas() {
            this.axios
                .post("/relatorios/planilhas-importadas")
                .then((response) => {
                    console.log(response);
                    this.planilhas = response.data.dados
                })
                .catch(() => {
                    self.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        exportCSV2() {
            this.$refs.rf.exportCSV();
        },
        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
  
<style scoped lang="scss">
@import "../../assets/demo/badges.scss";
</style>
  